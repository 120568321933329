.my-list-item[data-v-63e67857]:hover {
  background-color: #f4f4f5;
  color: red;
}
.my-list-item-button[data-v-63e67857]:hover {
  text-align: center;
  color: red;
}
.page-pagetable[data-v-63e67857] {
  overflow-y: auto;
}
